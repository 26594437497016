body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiTypography-caption {
  color: rgba(0, 0, 0, 0.54);
  line-height: 1.375em;
  letter-spacing: unset;
}

.MuiTableRow-root:nth-child(even) {
  background-color: #bee1fe21;
}

.MuiTableCell-root {
  padding: 14px 10px 14px 10px !important;
}

::-webkit-scrollbar-track {
  background-color: white;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;

  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(122, 153, 217)),
    color-stop(0.72, rgb(73, 125, 189)),
    color-stop(0.86, rgb(28, 58, 148))
  );
}

.fullHeight {
  height: 90vh;
}

@keyframes makeItGrow {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
